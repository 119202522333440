import React from 'react';
import { Nav } from '@douyinfe/semi-ui';
import { IconInfoCircle, IconMailStroked1 } from '@douyinfe/semi-icons';

import LoginMenu from '../login/Login';

class HeadMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Nav
                mode={'horizontal'}
                header={{
                    logo: <img src="https://sf6-cdn-tos.douyinstatic.com/obj/eden-cn/ptlz_zlp/ljhwZthlaukjlkulzlp/root-web-sites/webcast_logo.svg" />,
                    text: 'CyberRose'
                }}
                className="horizontalMenu"
            >
                <div style={{ marginLeft: 'auto', display: 'flex' }}>
                    <Nav.Item itemKey={'email'} text={'Contacts'} link={'/contacts'} icon={<IconMailStroked1 />}/>
                    <Nav.Item itemKey={'info'} text={'About'} link={'/about'} icon={<IconInfoCircle />}/>
                    <LoginMenu
                        behaviour={this.props.behaviour}
                        keycloak={this.props.keycloak}
                        onLoginChange={this.props.onLoginChange}
                    />
                </div>
            </Nav>
        );
    }
}

export default HeadMenu;

