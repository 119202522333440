import { IconFlag, IconLayers, IconCheckList, IconUserGroup, IconDesktop, IconPhoneStroke, IconUnlock, IconCode, IconSearch, IconServer, IconPulse, IconDelete, IconPuzzle, IconUserSetting } from '@douyinfe/semi-icons';

export const services = [
    {
        id: 1,
        name: "Pentest",
        price: {
            min: "4 000$",
            max: "1 000 000$"
        },
        range: {
            min: "1 week",
            max: "3 months"
        },
        description: "we can hack your site with your consent. We can analyze the security of your system and your network.",
        warning: true,
        icon: <IconUnlock />,
        buttons: [
            {
                name: "Order",
                action: "cart"
            },
            {
                name: "Information",
                action: "readme"
            }
        ]
    },
    {
        id: 2,
        name: "Web-development",
        price:  {
            min: "100$",
            max: "5 000$"
        },
        range: {
            min: "2 days",
            max: "3 months"
        },
        description: "we can create for you any site or web-platform using any CMS or CMF. If you want, we can provide you SEO or target advertisment.",
        warning: false,
        icon: <IconCode />,
        buttons: [
            {
                name: "Order",
                action: "cart"
            },
            {
                name: "Information",
                action: "readme"
            }
        ]
    },
    {
        id: 3,
        name: "System administration",
        price:  {
            min: "500$",
            max: "6 000$"
        },
        range: {
            min: "1 day",
            max: "1 week"
        },
        description: "we can install and configure for you the entire network of the organization, including all servers and workstations. We can install and configure for you a phone station (such as pure Asterisk or Issabel), proxy server, mail server, etc.",
        warning: false,
        icon: <IconUserSetting />,
        buttons: [
            {
                name: "Order",
                action: "cart"
            },
            {
                name: "Information",
                action: "readme"
            }
        ]
    },
    {
        id: 4,
        name: "Server programming",
        price:  {
            min: "1 250$",
            max: "15 000$"
        },
        range: {
            min: "1 week",
            max: "8 months"
        },
        description: "we can develop different types of applications for server architecture, using all types of databases and OS. After developing we can install and configure this app in your server.",
        warning: false,
        icon: <IconServer />,
        buttons: [
            {
                name: "Order",
                action: "cart"
            },
            {
                name: "Information",
                action: "readme"
            }
        ]
    },
    {
        id: 5,
        name: "Desktop programming",
        price:  {
            min: "500$",
            max: "7 500$"
        },
        range: {
            min: "1 week",
            max: "1 month"
        },
        description: "we can develop different types of applications for desktop or workstation, using all types of databases and OS. After developing we can install and configure this app in your computer.",
        warning: false,
        icon: <IconDesktop />,
        buttons: [
            {
                name: "Order",
                action: "cart"
            },
            {
                name: "Information",
                action: "readme"
            }
        ]
    },
    {
        id: 6,
        name: "Database development",
        price:  {
            min: "2 000$",
            max: "500 000$"
        },
        range: {
            min: "1 week",
            max: "4 months"
        },
        description: "our specialists can grab your database for outsource to it design. Also we can design a new database for you. We work with all types of database control systems.",
        warning: false,
        icon: <IconLayers />,
        buttons: [
            {
                name: "Order",
                action: "cart"
            },
            {
                name: "Information",
                action: "readme"
            }
        ]
    },
    {
        id: 7,
        name: "Mobile programming",
        price:  {
            min: "200$",
            max: "10 000$"
        },
        range: {
            min: "1 week",
            max: "6 months"
        },
        description: "you can take us to outsource for developing your mobile application or order to create a new application for different mobile platforms such as iOS and Android.",
        warning: false,
        icon: <IconPhoneStroke />,
        buttons: [
            {
                name: "Order",
                action: "cart"
            },
            {
                name: "Information",
                action: "readme"
            }
        ]
    },
    {
        id: 8,
        name: "GameDev",
        price:  {
            min: "500$",
            max: "undefined"
        },
        range: {
            min: "1 week",
            max: "2 years"
        },
        description: "if you need a specialists from game development sphere (ex., game designers, programmers, scenario writers or painters) we can join your project or take it for outsource. We can create a new game from zero for you.",
        warning: false,
        icon: <IconFlag />,
        buttons: [
            {
                name: "Order",
                action: "cart"
            },
            {
                name: "Information",
                action: "readme"
            }
        ]
    },
    {
        id: 9,
        name: "Corporate identity",
        price:  {
            min: "1 500$",
            max: "33 000$"
        },
        range: {
            min: "1 week",
            max: "6 months"
        },
        description: "if you want to customize your corporate style (ex., building own OS distribution for workstations or servers with fixed group of application, with own wallpappers and color shemes) we can deal it for you.",
        warning: false,
        icon: <IconUserGroup />,
        buttons: [
            {
                name: "Order",
                action: "cart"
            },
            {
                name: "Information",
                action: "readme"
            }
        ]
    },
    {
        id: 10,
        name: "Code analyse",
        price:  {
            min: "500$",
            max: "2 000$"
        },
        range: {
            min: "1 day",
            max: "1 month"
        },
        description: "if you want to analyse your application code (ex, for optimize or vulnerability deprecation such as backdoors) we  will help you with it.",
        warning: false,
        icon: <IconPulse />,
        buttons: [
            {
                name: "Order",
                action: "cart"
            },
            {
                name: "Information",
                action: "readme"
            }
        ]
    },
    {
        id: 11,
        name: "Data recovery",
        price:  {
            min: "250$",
            max: "15 000$"
        },
        range: {
            min: "1 day",
            max: "2 months"
        },
        description: "if you have a the data lost problem (ex., falling hard disk, water filling hard disk, formatting disk) we can recover your data.",
        warning: false,
        icon: <IconDelete />,
        buttons: [
            {
                name: "Order",
                action: "cart"
            },
            {
                name: "Information",
                action: "readme"
            }
        ]
    },
    {
        id: 12,
        name: "Integrations",
        price:  {
            min: "100$",
            max: "20 000$"
        },
        range: {
            min: "2 days",
            max: "4 months"
        },
        description: "if you want to integrate some services (like dadata.ru, delivery services, etc) with your services or platforms, we can do it for you.",
        warning: false,
        icon: <IconPuzzle />,
        buttons: [
            {
                name: "Order",
                action: "cart"
            },
            {
                name: "Information",
                action: "readme"
            }
        ]
    },
    {
        id: 13,
        name: "OSINT",
        price:  {
            min: "1000$",
            max: "10000$"
        },
        range: {
            min: "1 day",
            max: "1 week"
        },
        description: "we can find for you any information about anything, such as people, technologies, data leaks, new vulnerabilities, etc. Its necessary for reputation monitoring, market intelligence, risk management, etc.",
        warning: false,
        icon: <IconSearch />,
        buttons: [
            {
                name: "Order",
                action: "cart"
            },
            {
                name: "Information",
                action: "readme"
            }
        ]
    },
    {
        id: 14,
        name: "Business by steps",
        price:  {
            min: "undefined",
            max: "undefined"
        },
        range: {
            min: "undefined",
            max: "undefined"
        },
        description: "Our team will help you create your business from scratch. All work will take place in stages: it includes an analysis of the market for your future business, an analysis of your competitors and potential profits; we will help you buy equipment, buy or create required software, help with advert, etc.",
        warning: false,
        icon: <IconCheckList />,
        buttons: [
            {
                name: "Order",
                action: "cart"
            },
            {
                name: "Information",
                action: "readme"
            }
        ]
    }
]

