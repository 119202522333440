import React from 'react';
import { Nav } from '@douyinfe/semi-ui';
import { IconHome, IconUser, IconSetting, IconCart, IconTerminal, IconSourceControl} from '@douyinfe/semi-icons';

const items = [
    { itemKey: 'home', text: 'Home', link: '/home', icon: <IconHome /> },
    { itemKey: 'profile', text: 'Profile', link: '/profile', icon: <IconUser /> },
    { itemKey: 'projects', text: 'Projects', link: '/projects', icon: <IconSourceControl /> },
    { itemKey: 'services', text: 'Services', link: '/services', icon: <IconTerminal /> },
    { itemKey: 'shop', text: 'Shop', link: '/shop', icon: <IconCart /> },
    { itemKey: 'settings', text: 'Settings', link: '/settings', icon: <IconSetting /> },
];

class SideMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Nav
                bodyStyle={{ height: 320 }}
                items={items}
                onSelect={key => console.log(key)}
                className="verticalMenu"
                style={{height: '100%'}}
            />
        );
    }
}

export default SideMenu;

