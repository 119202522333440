import React from 'react';
import { Col, Row, Card, Typography } from '@douyinfe/semi-ui';
import { services } from './data/services';
import { isEmpty } from 'lodash';
import { IconCart } from '@douyinfe/semi-icons';

class ServicesPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="grid" style={{padding: "25px", overflowY: "scroll"}}>
                <Row type="flex" style={{padding: '25px', marginBottom: '50px'}}>
                    { !isEmpty(services) ?
                        services.map((item) => {
                            return (
                                <Col span={8} order={item.id}>
                                    <div className="col-content">
                                        <Card 
                                            title={<Typography.Text style={{lineHeight: '24px', verticalAlign: 'middle'}}>{item.icon} <b style={{paddingLeft: '8px'}}>{item.name}</b></Typography.Text>}
                                            style={{ maxWidth: 360, margin: "10px", minHeight: '240px', maxHeight: '240px'}}
                                            className="cardBody"
                                            headerExtraContent={
                                                <Typography.Text link>
                                                    <IconCart />
                                                </Typography.Text>
                                            }
                                        >
                                            <div style={{maxHeight: '80%', overflowY: 'auto'}}>
                                                <Typography.Text className="textBlock">Price: from <b>{item.price.min}</b> to <b>{item.price.max}</b></Typography.Text>
                                                <Typography.Text className="textBlock">Range: from <b>{item.range.min}</b> to <b>{item.range.max}</b></Typography.Text>
                                                <Typography.Text className="textBlock">Description: {item.description}</Typography.Text>
                                            </div>
                                        </Card>
                                    </div>
                                </Col>
                            )
                        }):
                        <span style={{display: "block", textAlign: "center"}}>Данные отсутствует. Выберите сервер.</span>
                    }
                </Row>
            </div>
        );
    }
}

export default ServicesPage;

